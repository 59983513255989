.min-w-175 {
  min-width: 175px !important;
}
.min-w-200 {
  min-width: 200px !important;
}
img.img-border {
  background: #fff;
  border: 1px solid #ddd;
  padding: 4px;
}
.content-header .pager-wrapper {
  float: right;
  position: absolute;
  top: -5px;
  right: 15px;
}
.content-header .pager-wrapper > .pager li > a,
.content-header .pager-wrapper > .pager li > span {
  border-radius: 3px;
}
.box-header .time {
  margin-right: 10px;
  font-size: 12px;
  color: #777;
}
.box-footer > .list-action-links {
  margin-bottom: 0;
  font-size: .9em;
}
.box-footer > .list-action-links li {
  padding-left: 10px;
  padding-right: 10px;
}
.info-box-icon a {
  color: inherit;
}
.info-box-icon a:hover {
  -webkit-filter: brightness(75%);
  filter: brightness(75%);
}
.info-box-buttons {
  padding-top: 10px;
}
.info-box-buttons .btn-app {
  min-width: 50px;
}
.form-group.form-required-clabel-only label.control-label:after,
.form-group.form-required label:after,
.checkbox.form-required label:after {
  content: " *";
  color: #c00;
  font-weight: bold;
}
.form-group.form-required.radio label:after,
.radio.form-required label:after {
  content: "";
}
.datepicker-inline,
.date {
  width: 100%;
}
.input-group-addon .info-weekday {
  margin-left: 5px;
  width: 1em;
  display: inline-block;
}
.form-appointment input.datepicker {
  min-width: 110px;
}
/* Form Recurrence Options */
.recurrence-options {
  margin-bottom: 15px;
  /*border-bottom: 1px #dddddd solid;*/
}
.recurrence-options .radio-with-input input[type="radio"] {
  margin-top: 8px !important;
}
.recurrence-options .radio-with-input select,
.recurrence-options .radio-with-input .selectize-control {
  min-width: 150px !important;
  margin-left: 5px;
  margin-right: 5px;
}
.recurrence-options .checkbox-list {
  padding-left: 5px;
  padding-right: 5px;
}
.recurrence-options .checkbox-list label {
  padding-top: 0 !important;
}
.recurrence-options input[type="number"] {
  width: 70px;
  margin-left: 5px;
  margin-right: 5px;
}
.recurrence-options input[type="date"] {
  margin-left: 5px;
  margin-right: 5px;
}
/* Popover */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 476px;
  min-width: 200px;
  padding: 1px;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  background-color: #fff;
  line-break: auto;
}
.popover-title {
  padding: 8px 9px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px;
}
/* Appointment Details (shown in Popover and Modal) */
.appointment-details .popover-content time,
.appointment-details .appointment-details-content time {
  display: block;
  font-weight: 600;
  margin-bottom: 15px;
}
.appointment-details .popover-content time .all-day,
.appointment-details .appointment-details-content time .all-day {
  margin-left: 5px;
}
.appointment-details .popover-content .info,
.appointment-details .appointment-details-content .info {
  margin-bottom: 5px;
}
.appointment-details .popover-content .info i.fa,
.appointment-details .appointment-details-content .info i.fa {
  float: left;
  width: 1.28571429em;
  text-align: center;
  line-height: inherit;
}
.appointment-details .popover-content .info p,
.appointment-details .appointment-details-content .info p {
  margin: 0;
  padding-left: 1.75em;
}
.appointment-details .appointment-details-content .info p .calendar-name {
  border-bottom: 3px solid grey;
  padding-bottom: 3px;
}
.appointment-details .popover-content p,
.appointment-details .appointment-details-content p {
  margin: 15px 0;
}
.appointment-details .popover-content .additional-info,
.appointment-details .appointment-details-content .additional-info {
  border-top: 1px solid #ebebeb;
  padding-top: 5px;
}
.appointment-details .appointment-details-content .additional-info .info:last-of-type {
  margin-bottom: 0;
}
.appointment-details .popover-footer {
  border-top: 1px solid #ebebeb;
  padding: 9px 14px 25px;
}
.appointment-details .modal-title .fa-lock {
  margin-right: 10px;
  line-height: inherit;
}
.appointment-details .modal-header .close {
  margin-top: 2px;
}
.appointment-details .modal-body {
  padding: 0;
}
.appointment-details .modal-body .appointment-details-content {
  padding: 15px;
}
.table-condensed-off .table-condensed > thead > tr > th,
.table-condensed-off .table-condensed > tbody > tr > th,
.table-condensed-off .table-condensed > tfoot > tr > th,
.table-condensed-off .table-condensed > thead > tr > td,
.table-condensed-off .table-condensed > tbody > tr > td,
.table-condensed-off .table-condensed > tfoot > tr > td {
  padding: 0px;
}
/* Selectize */
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  /*inset 0 0 12px 4px #ffffff;*/
  box-shadow: none;
  /* inset 0 0 12px 4px #ffffff;*/
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  /*0 2px 5px rgba(0, 0, 0, 0.2);*/
  box-shadow: none;
  /*0 2px 5px rgba(0, 0, 0, 0.2);*/
}
.selectize-dropdown-header {
  position: relative;
  padding: 3px 12px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  /*4px 4px 0 0;*/
  /*4px 4px 0 0;*/
  border-radius: 0;
  /*4px 4px 0 0;*/
}
.selectize-dropdown-header-close {
  position: absolute;
  right: 12px;
  top: 50%;
  color: #333333;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
}
.selectize-control {
  position: relative;
}
.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: #333333;
  font-family: inherit;
  font-size: inherit;
  line-height: 20px;
  -webkit-font-smoothing: inherit;
}
.selectize-input,
.selectize-control.single .selectize-input.input-active {
  background: #ffffff;
  cursor: text;
  display: inline-block;
}
.selectize-input {
  /*border: 1px solid #cccccc;
  padding: 6px 12px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;*/
  box-shadow: none;
  /*4px;*/
  border-radius: 0;
}
.selectize-input.focus {
  /*inset 0 1px 2px rgba(0, 0, 0, 0.15);*/
  box-shadow: none;
  /*inset 0 1px 2px rgba(0, 0, 0, 0.15);*/
}
.selectize-input.dropdown-active {
  border-radius: 0;
  /*4px 4px 0 0;*/
}
.selectize-input > * {
  vertical-align: baseline;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.selectize-control.multi .selectize-input > div {
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 1px 3px;
  background: #efefef;
  color: #333333;
  border: 0 solid rgba(0, 0, 0, 0);
}
.selectize-control.multi .selectize-input > div.active {
  background: #3c8dbc;
  /*#428bca;*/
  color: #ffffff;
  border: 0 solid rgba(0, 0, 0, 0);
}
.selectize-dropdown {
  position: absolute;
  z-index: 10;
  border: 1px solid #d0d0d0;
  background: #ffffff;
  margin: -1px 0 0 0;
  border-top: 0 none;
  box-sizing: border-box;
  /*0 1px 3px rgba(0, 0, 0, 0.1);*/
  box-shadow: none;
  /*0 1px 3px rgba(0, 0, 0, 0.1);*/
  /*0 0 4px 4px;*/
  /*0 0 4px 4px;*/
  border-radius: 0;
  /*0 0 4px 4px;*/
}
.selectize-dropdown .active {
  /*background-color: #f5f5f5;
  color: #262626;*/
  background-color: #3c8dbc;
  color: white;
}
.selectize-dropdown .active.create {
  color: white;
  /*color: #262626;*/
}
.selectize-dropdown .create {
  color: rgba(51, 51, 51, 0.5);
}
.selectize-dropdown,
.selectize-dropdown.form-control {
  height: auto;
  padding: 0;
  margin: 2px 0 0 0;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  /*4px;*/
  /*4px;*/
  border-radius: 0;
  /*4px;*/
  /*0 6px 12px rgba(0, 0, 0, 0.175);*/
  box-shadow: none;
  /*0 6px 12px rgba(0, 0, 0, 0.175);*/
}
.selectize-input.dropdown-active {
  /*4px;*/
  /*4px;*/
  border-radius: 0;
  /*4px;*/
}
.selectize-input:focus,
.selectize-input.focus {
  border-color: #3c8dbc;
  /*#66afe9;*/
  outline: 0;
  /*inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);*/
  box-shadow: none;
  /*inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);*/
}
.has-error .selectize-input {
  border-color: #a94442;
  /*inset 0 1px 1px rgba(0, 0, 0, 0.075);*/
  box-shadow: none;
  /*inset 0 1px 1px rgba(0, 0, 0, 0.075);*/
}
.has-error .selectize-input:focus {
  border-color: #843534;
  /*inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;*/
  box-shadow: none;
  /*inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;*/
}
.selectize-control.multi .selectize-input > div {
  border-radius: 3px;
}
/* Selectize Custom */
.selectize-control.multi .selectize-input > div {
  background: #ddd;
  padding: 2px 6px;
}
/*.selectize-dropdown .active {
  background-color: #e7e7e7;
}*/
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right),
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    width: 200px;
  }
}
.btn-dark {
  background-color: #222d32;
  color: #fff !important;
}

/*# sourceMappingURL=app.css.map */
